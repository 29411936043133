import React from "react";
import {Button, Checkbox, Form, Input, message} from "antd";
import {useNavigate} from "react-router-dom";
import {doLogin, setToken} from "../config/api";
import {useDispatch} from "react-redux";
import {setUserInfo} from "../store/userSlice";
import {LockOutlined, UserOutlined} from "@ant-design/icons";

const Login = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const formRef = React.useRef(null);

    const login = (values) => {
        doLogin({...values, loginType: "account"})
            .then((response) => {
                if (response.result) {
                    message.success("登录成功").then()
                    dispatch(setUserInfo(response.userInfo))
                    setToken(response.token)
                    navigate("/");
                } else {
                    message.error("用户名或密码错误").then()
                    formRef.current?.resetFields();
                }
            })
    };

    return (
        <div style={{backgroundColor: "#EEEEEE", height: "100vh"}}>
            <div style={{margin: "0 auto", padding: "40px 0 20px", maxWidth: 150, textAlign: "center"}}>
                <img src="/logo.png" style={{width: "100%", paddingLeft: 10}} alt=""/>
                <div style={{color: "#265825", fontSize: 20}}>LeanOn</div>
            </div>

            <div style={{padding: "0 20px", margin: "0 auto", maxWidth: 350}}>
                <Form
                    ref={formRef}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={login}
                    layout="vertical"
                    requiredMark={false}
                >
                    <Form.Item
                        label="用户名"
                        name="userName"
                        rules={[
                            {
                                required: true,
                                // message: 'Please input your username!',
                            },
                        ]}
                    >
                        <Input prefix={<UserOutlined />} placeholder="请输入用户名" />
                    </Form.Item>

                    <Form.Item
                        label="密码"
                        name="password"
                        rules={[
                            {
                                required: true,
                                // message: 'Please input your password!',
                            },
                        ]}
                    >
                        <Input.Password prefix={<LockOutlined />} placeholder="请输入密码" />
                    </Form.Item>

                    <Form.Item name="remember" valuePropName="checked">
                        <Checkbox>记住登录状态</Checkbox>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" block>
                            登录
                        </Button>
                    </Form.Item>
                </Form>
            </div>
            <div style={{width: "100%", padding: "60px 0", textAlign: "center", fontSize: 14, color: "#999", flexWrap: "wrap", justifyContent: "center", gap: "0 20px", marginTop: "auto"}}>
                <div>Copyright © 2023 LeanOn.Co</div>
                {/*<span style={{color: "#999", textDecoration: "underline", cursor: "pointer"}} onClick={() => window.open("https://beian.miit.gov.cn/")}>京ICP备2023012057号-2</span>*/}
                <div><a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备2023020878号-3</a></div>
                <div>Design By <span style={{color: "#999", fontWeight: 700, textDecoration: "underline", cursor: "pointer"}} onClick={() => window.open("https://github.com/XiaoBaiOvO")}>Ethan Hong</span></div>
            </div>
        </div>
    );
};

export default Login;