import React, {useEffect, useState} from "react";
import {Button, Dropdown, Empty, message, Space} from "antd";
import {useNavigate} from "react-router-dom";
import {getUserList} from "../config/api";
import Footer from "./layout/footer";
import {DownOutlined, FullscreenExitOutlined, FullscreenOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import {setActiveId} from "../store/userSlice";

export default () => {

    const [userList, setUserList] = useState([])
    const [tempName, setTempName] = useState("")
    const [intervalId, setIntervalId] = useState(null)
    const [name, setName] = useState("")
    const [full, setFull] = useState(false)

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {activeId} = useSelector(state => state.user);

    const start = () => {
        // let i = 0
        // setTempName(userList[i])
        // let id = setInterval(() => {
        //     setTempName(userList[i])
        //     if (i === userList.length - 1) {
        //         i = 0
        //     } else {
        //         i ++
        //     }
        // }, 5)
        // setIntervalId(id)
        navigate("/gifting")

    }
    const end = () => {
        clearInterval(intervalId)
        setIntervalId(null)
        message.success(document.getElementById("displayed").innerText).then()
    }

    useEffect(() => {
        clearInterval(intervalId)
        refreshList()
        let id = setInterval(() => refreshList(), 3000)
        setIntervalId(id)
    }, [activeId])

    const refreshList = () => {
        getUserList({trainingId: 10041}).then(r => {
            console.log(r)
            setUserList(r)
        })
    }

    const items = [
        {
            key: '0',
            id: "10041",
            label: "宁波站",
        },
    ];

    const onClick = (e) => {
        console.log(e.key)
        dispatch(setActiveId(e.key))
    }

    return (
        // <div style={{backgroundColor: "#eeeeee", height: "100vh", width: "100vw", display: "flex", flexDirection: "column"}}>
        //
        //     <div style={{padding: "10px 20px", boxShadow: "0 2px 5px #757575", display: "flex", alignItems: "center", backgroundColor: "rgb(0,110,70)", color: "rgb(205,218,185)"}}>
        //         <div style={{flex: 1, fontSize: 22, display: "flex", gap: 20}}>LeanOn</div>
        //         {full ?
        //             <FullscreenExitOutlined style={{fontSize: 20}} onClick={() => {
        //                 setFull(false)
        //                 if (document.exitFullscreen) {
        //                     document.exitFullscreen();
        //                 } else if (document.mozCancelFullScreen) { // 兼容Firefox
        //                     document.mozCancelFullScreen();
        //                 } else if (document.webkitExitFullscreen) { // 兼容Chrome, Safari和Opera
        //                     document.webkitExitFullscreen();
        //                 } else if (document.msExitFullscreen) { // 兼容IE/Edge
        //                     document.msExitFullscreen();
        //                 }
        //             }}/> :
        //             <FullscreenOutlined style={{fontSize: 20}} onClick={() => {
        //                 setFull(true)
        //                 if (document.documentElement.requestFullscreen) {
        //                     document.documentElement.requestFullscreen();
        //                 } else if (document.documentElement.mozRequestFullScreen) { // 兼容Firefox
        //                     document.documentElement.mozRequestFullScreen();
        //                 } else if (document.documentElement.webkitRequestFullscreen) { // 兼容Chrome, Safari和Opera
        //                     document.documentElement.webkitRequestFullscreen();
        //                 } else if (document.documentElement.msRequestFullscreen) { // 兼容IE/Edge
        //                     document.documentElement.msRequestFullscreen();
        //                 }
        //             }}/>
        //         }
        //
        //     </div>
        //
        //     <div style={{flex: 1, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
        //         <div style={{width: 1500, height: 1000, backgroundColor: "#fff", borderRadius: 20}}>
        //             <div style={{display: "flex"}}>
        //                 <div style={{
        //                     padding: 20,
        //                     borderRadius: 20,
        //                     maxWidth: 600,
        //                     margin: "20px auto 0",
        //                     backgroundColor: "white"
        //                 }}>
        //                     <div style={{display: "flex"}}>
        //                         <div style={{marginBottom: 10, fontSize: 18, fontWeight: 800, whiteSpace: "nowrap"}}>抽奖活动</div>
        //                         <div style={{marginLeft: "auto", lineHeight: "26px", whiteSpace: "nowrap"}}>参与签到，即可进行抽奖</div>
        //                     </div>
        //                     <div style={{padding: "30px 40px", display: "flex", flexDirection: "column", alignItems: "center"}}>
        //                         <img src="https://ebai.oss-cn-shanghai.aliyuncs.com/gift.png" alt="" style={{objectFit: "cover", width: "100%"}}/>
        //                         <div id="displayed" style={{fontSize: 20, marginTop: 20}}>臻护美肌精选礼盒</div>
        //                         <div id="displayed" style={{fontSize: 15, color: "gray" }}>价值 598 元</div>
        //                     </div>
        //                     <div style={{padding: "0 40px"}}>
        //                         <Button block type="primary" loading={false} style={{color: "rgb(205,218,185)"}} onClick={start}>开始抽奖</Button>
        //                     </div>
        //                 </div>
        //                 <div style={{
        //                     padding: 20,
        //                     borderRadius: 20,
        //                     maxWidth: 600,
        //                     margin: "20px auto 0",
        //                     backgroundColor: "white"
        //                 }}>
        //                     <div style={{display: "flex"}}>
        //                         <div style={{marginBottom: 10, fontSize: 18, fontWeight: 800, whiteSpace: "nowrap"}}>已签到名单</div>
        //                         <div style={{marginLeft: "auto", lineHeight: "26px", whiteSpace: "nowrap"}}>总计: {userList.length} 人</div>
        //                     </div>
        //                     {userList.map((item, index) =>
        //                         <div key={index} style={{display: "flex", lineHeight: "25px"}}>
        //                             <div style={{flex: 1}}>
        //                                 {index + 1}. {item.realName}
        //                             </div>
        //                             <div style={{color: "gray"}}>{item.department.slice(0, -6)}</div>
        //                             <div style={{color: "gray", marginLeft: 20}}>{item.clockDate.slice(11)}</div>
        //                         </div>
        //                     )}
        //                 </div>
        //             </div>
        //         </div>
        //         <div style={{paddingTop: 20, fontSize: 14, color: "#999"}}>
        //             <div>Copyright © 2016 - 2023 LeanOn</div>
        //         </div>
        //     </div>
        // </div>
        <div style={{width: "100vw", height: "100vh", backgroundColor: "#cce2da", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", fontSize: 18}}>
            <div style={{position: "absolute",top: 10, right: 10, color: "#fff", cursor: "pointer"}}>
                {full ?
                    <FullscreenExitOutlined style={{fontSize: 24}} onClick={() => {
                        setFull(false)
                        if (document.exitFullscreen) {
                            document.exitFullscreen();
                        } else if (document.mozCancelFullScreen) { // 兼容Firefox
                            document.mozCancelFullScreen();
                        } else if (document.webkitExitFullscreen) { // 兼容Chrome, Safari和Opera
                            document.webkitExitFullscreen();
                        } else if (document.msExitFullscreen) { // 兼容IE/Edge
                            document.msExitFullscreen();
                        }
                    }}/> :
                    <FullscreenOutlined style={{fontSize: 24}} onClick={() => {
                        setFull(true)
                        if (document.documentElement.requestFullscreen) {
                            document.documentElement.requestFullscreen();
                        } else if (document.documentElement.mozRequestFullScreen) { // 兼容Firefox
                            document.documentElement.mozRequestFullScreen();
                        } else if (document.documentElement.webkitRequestFullscreen) { // 兼容Chrome, Safari和Opera
                            document.documentElement.webkitRequestFullscreen();
                        } else if (document.documentElement.msRequestFullscreen) { // 兼容IE/Edge
                            document.documentElement.msRequestFullscreen();
                        }
                    }}/>
                }
            </div>

            <div style={{width: "100%", height: "100%", maxWidth: 1500, maxHeight: 800, margin: 20, display: "flex", flexDirection: "column", gap: 20}}>
                <div style={{backgroundColor: "#fff", padding: 20, borderRadius: 20, display: "flex", alignItems: "center"}}>
                    <div style={{flex: 1, fontSize: 22}}>LeanOn</div>
                    <div style={{fontSize: 24}}>两两9月堂训 一 <Dropdown
                        menu={{
                            items,
                            onClick
                        }}
                    >
                        <a onClick={(e) => e.preventDefault()}>
                            <Space>
                                <span style={{fontWeight: 800}}>宁波站</span>
                                <DownOutlined style={{fontSize: 16}} onChange={(e) => console.log(e)}/>
                            </Space>
                        </a>
                    </Dropdown>
                        </div>
                    <div style={{flex: 1, textAlign: "right"}}>2023-09-21</div>
                </div>
                <div style={{flex: 1, display: "flex", gap: 20, overflow: "auto"}}>
                    {/*left*/}
                    <div style={{backgroundColor: "#fff", flex: 1, borderRadius: 20, display: "flex", flexDirection: "column",}}>
                        <div style={{backgroundColor: "#006E46", borderRadius: "20px 20px 0 0", padding: "15px 20px", color: "#fff", display: "flex", alignItems: "center"}}>
                            <div style={{flex: 1, fontSize: 22, fontWeight: 800}}>抽奖奖品</div>
                            <div>参与签到，即可进行抽奖</div>
                        </div>
                        <div style={{height: "100%", display: "flex", flexDirection: "column", alignItems: "center",padding: "60px 0 40px"}}>
                            <div>
                                <div style={{textAlign: "center", fontSize: 22, fontWeight: 800}}>两两臻护美肌精选礼盒</div>
                                <div style={{textAlign: "center"}}>价值 <span style={{color: "#f00"}}>239</span> 元</div>
                            </div>
                            <div style={{flex: 1, display: "flex", alignItems: "center", justifyContent: "center"}}>
                                <div style={{backgroundImage: "url('https://10kv.oss-cn-shanghai.aliyuncs.com/gift/gift2.jpg')", backgroundSize: "100% 100%", width: 500, height: "100%"}}></div>

                            </div>
                            <Button type="primary" loading={false} style={{color: "rgb(205,218,185)", width: "80%"}} onClick={start}>开始抽奖</Button>
                        </div>
                    </div>
                    {/*right*/}
                    <div style={{backgroundColor: "#fff", flex: 1, borderRadius: 20, display: "flex", flexDirection: "column", overflow: "auto"}}>
                        <div style={{backgroundColor: "#006E46", borderRadius: "20px 20px 0 0", padding: "15px 20px", color: "#fff", display: "flex", alignItems: "center"}}>
                            <div style={{flex: 1, fontSize: 22, fontWeight: 800}}>已签到名单</div>
                            <div>总计：{userList.length} 人</div>
                        </div>
                        <div style={{overflow: "auto"}} className="name-list">
                            {userList.map((item, index) =>
                                <div key={index} style={{display: "flex", padding: "10px 20px", backgroundColor: index % 2 === 0 ? "#fff" : "#e5f0ec"}}>
                                    <div style={{width: 40}}>{index + 1}.</div>
                                    <div style={{fontWeight: 800}}>{item.realName}</div>
                                    <div style={{flex: 1, textAlign: "center", color: "#888"}}>{item.department.slice(0, -6)}</div>
                                    <div style={{color: "#888"}}>{item.clockDate.slice(11)}</div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

            </div>

            <div style={{color: "#aaa"}}>Copyright @ 2016 - 2023 LeanOn</div>
            <div style={{color: "#aaa"}}>All rights reserved.</div>

        </div>
    )
}