import React, {useEffect, useState} from "react";
import "../css/index.css"
import {Button, Dropdown, Empty, message, Space} from "antd";
import {getUserList} from "../config/api";
import {DownOutlined, FullscreenExitOutlined, FullscreenOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {setActiveId} from "../store/userSlice";

export default () => {

    const [userList, setUserList] = useState([])
    const [giftList, setGiftList] = useState([])
    const [tempName, setTempName] = useState(null)
    const [intervalId, setIntervalId] = useState(null)
    const [full, setFull] = useState(false)

    const dispatch = useDispatch();
    const {activeId} = useSelector(state => state.user);

    const start = () => {
        let total = userList.length
        let id = setInterval(() => {
            setTempName(Math.floor(Math.random() * total))
        }, 2)
        setIntervalId(id)
    }

    const onClick = (e) => {
        console.log(e.key)
        dispatch(setActiveId(e.key))
    }

    useEffect(() => {
        getUserList({trainingId: 10041}).then(r => {
            console.log(r)
            setUserList(r.map(item => {
                return item.realName
            }))
        })
    }, [activeId])

    const end = () => {
        clearInterval(intervalId)
        setIntervalId(null)

        let newOne = userList[tempName]

        while (giftList.includes(newOne)) {
            let newIndex = Math.floor(Math.random() * userList.length)
            newOne = userList[newIndex]
            setTempName(newIndex)
        }

        setGiftList([newOne, ...giftList])
        setTimeout(() => {
            message.success("恭喜 " + newOne + " 获得奖品一份").then()
            setGiftList([newOne, ...giftList])
        }, 0)

        // let name = document.getElementById("displayed").innerText
        // // setTempName("洪运成")
        // setTempName(name)
        // setTimeout(() => {
        //     message.success("恭喜 " + name + " 获得奖品一份").then()
        //     setGiftList([...giftList, name])
        // }, 500)
    }

    return (
        // <div className="slow-show" style={{height: "100vh", width: "100vw", overflow: "hidden"}}>
        //     <div className="title" style={{padding: "40px 0"}}>抽奖活动</div>
        //     <div className="peoples1" style={{display: "flex", gap: 20, fontSize: 30}}>
        //         {[{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}].map(() =>
        //         {return (userList.map((item, index) =>
        //             <div key={index} className="nameCard">{item}</div>
        //         ))})}
        //     </div>
        //    <div style={{padding: "0 20px"}}>
        //        <div className="play" style={{maxWidth: 500, margin: "80px auto", display: "flex", flexDirection: "column", gap: 20}}>
        //            <div id="displayed" style={{padding: 20, backgroundColor: "rgba(255,255,255,0.6)", lineHeight: "120px", fontSize: 40, textAlign: "center", height: 120, fontFamily: "digitalFont", borderRadius: 20}} >
        //                {tempName}
        //            </div>
        //            {intervalId ? <Button block type="primary" loading={false} style={{color: "rgb(205,218,185)"}} onClick={end}>停止</Button> :
        //                <Button block type="primary" loading={false} style={{color: "rgb(205,218,185)"}} onClick={start}>开始</Button>}
        //        </div>
        //    </div>
        //     <div className="peoples2" style={{display: "flex", gap: 20, fontSize: 30}}>
        //         {[{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}].map(() =>
        //         {return (userList.map((item, index) =>
        //             <div key={index} className="nameCard">{item}</div>
        //         ))})}
        //     </div>
        //
        //     <div className="giftNameList" style={{
        //         display: giftList.length === 0 ? "none" : null,
        //         padding: 20,
        //         borderRadius: 20,
        //         maxWidth: 600,
        //         margin: "40px auto 0",
        //         backgroundColor: "rgba(255,255,255,0.6)"
        //     }}>
        //         <div style={{display: "flex"}}>
        //             <div style={{marginBottom: 10, fontSize: 18, fontWeight: 800, whiteSpace: "nowrap"}}>中奖名单</div>
        //             <div style={{marginLeft: "auto", lineHeight: "26px", whiteSpace: "nowrap"}}>总计: {giftList.length} 人</div>
        //         </div>
        //         {giftList.map((item, index) =>
        //             <div key={index}>
        //                 {index + 1}. {item}
        //             </div>
        //         )}
        //     </div>
        //
        // </div>

        <div style={{width: "100vw", height: "100vh", backgroundColor: "#cce2da", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", fontSize: 18}}>
            <div style={{position: "absolute",top: 10, right: 10, color: "#fff", cursor: "pointer"}}>
                {full ?
                    <FullscreenExitOutlined style={{fontSize: 24}} onClick={() => {
                        setFull(false)
                        if (document.exitFullscreen) {
                            document.exitFullscreen();
                        } else if (document.mozCancelFullScreen) { // 兼容Firefox
                            document.mozCancelFullScreen();
                        } else if (document.webkitExitFullscreen) { // 兼容Chrome, Safari和Opera
                            document.webkitExitFullscreen();
                        } else if (document.msExitFullscreen) { // 兼容IE/Edge
                            document.msExitFullscreen();
                        }
                    }}/> :
                    <FullscreenOutlined style={{fontSize: 24}} onClick={() => {
                        setFull(true)
                        if (document.documentElement.requestFullscreen) {
                            document.documentElement.requestFullscreen();
                        } else if (document.documentElement.mozRequestFullScreen) { // 兼容Firefox
                            document.documentElement.mozRequestFullScreen();
                        } else if (document.documentElement.webkitRequestFullscreen) { // 兼容Chrome, Safari和Opera
                            document.documentElement.webkitRequestFullscreen();
                        } else if (document.documentElement.msRequestFullscreen) { // 兼容IE/Edge
                            document.documentElement.msRequestFullscreen();
                        }
                    }}/>
                }
            </div>

            <div style={{width: "100%", height: "100%", maxWidth: 1500, maxHeight: 800, margin: 20, display: "flex", flexDirection: "column", gap: 20}}>
                <div style={{backgroundColor: "#fff", padding: 20, borderRadius: 20, display: "flex", alignItems: "center"}}>
                    <div style={{flex: 1, fontSize: 22}}>LeanOn</div>
                    {/*<div style={{fontSize: 24}}>两两9月堂训 一 <Dropdown*/}
                    {/*    menu={{*/}
                    {/*        items,*/}
                    {/*        onClick*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    <a onClick={(e) => e.preventDefault()}>*/}
                    {/*        <Space>*/}
                    {/*            <span style={{fontWeight: 800}}>宁波站</span>*/}
                    {/*            <DownOutlined style={{fontSize: 16}} onChange={(e) => console.log(e)}/>*/}
                    {/*        </Space>*/}
                    {/*    </a>*/}
                    {/*</Dropdown>*/}
                    {/*</div>*/}
                    <div style={{flex: 1, textAlign: "right"}}>2023-09-21</div>
                </div>
                <div style={{flex: 1, display: "flex", gap: 20, overflow: "auto"}}>
                    {/*left*/}
                    <div style={{backgroundColor: "#fff", flex: 5, borderRadius: 20, display: "flex", flexDirection: "column",}}>
                        <div style={{backgroundColor: "#006E46", borderRadius: "20px 20px 0 0", padding: "15px 20px", color: "#fff", display: "flex", justifyContent: "center"}}>
                            <div style={{fontSize: 22, fontWeight: 800}}>抽奖活动</div>
                        </div>
                        <div style={{flex: 1, display: "grid", padding: 20, gap: 20, gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr"}}>
                            {userList.map((item, index) => <div style={{backgroundColor: tempName === index ? "#006E46" : "#cce2da", color: tempName === index ? "#fff" : "#000", padding: "10px 20px", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: 10, fontSize: 20}}>{item}</div>)}
                        </div>

                        <div style={{padding: "0 20px 20px"}}>
                            {intervalId ?
                                <Button block type="primary" loading={false} style={{color: "rgb(205,218,185)"}} size="large" onClick={end}>停止</Button> :
                                <Button block type="primary" loading={false} style={{color: "rgb(205,218,185)"}} size="large" onClick={start}>开始</Button>
                            }
                        </div>
                    </div>
                    {/*right*/}
                    <div style={{backgroundColor: "#fff", flex: 2, borderRadius: 20, display: "flex", flexDirection: "column", overflow: "auto"}}>
                        <div style={{backgroundColor: "#006E46", borderRadius: "20px 20px 0 0", padding: "15px 20px", color: "#fff", display: "flex", alignItems: "center"}}>
                            <div style={{flex: 1, fontSize: 22, fontWeight: 800}}>中奖名单</div>
                            <div>总计：{giftList.length} 人</div>
                        </div>
                        <div style={{overflow: "auto", padding: 20, display: "flex", flexDirection: "column", gap: 20}} className="name-list">
                            {giftList.map((item, index) =>
                                    <div className="slow-show" key={index} style={{padding: index === 0 ? 40 : 20, fontSize: index === 0 ? 40 : 20, fontWeight: 800, textAlign: "center", borderRadius: 20, color: "#006E46"}}>
                                        {item}
                                    </div>
                                )}
                            {giftList.length === 0 && <Empty style={{marginTop: 40}}/>}
                        </div>
                    </div>
                </div>

            </div>

            <div style={{color: "#aaa"}}>Copyright @ 2016 - 2023 LeanOn</div>
            <div style={{color: "#aaa"}}>All rights reserved.</div>

        </div>
    )
}