import React from "react";
import {DesktopOutlined, MobileOutlined} from "@ant-design/icons";

export default () => {

    return (
        <div className="testPage">
            <div className="text1" style={{color: "white"}}>AIPal 使用邀请函</div>
            <div className="text2" style={{color: "white"}}>从这里开启搜索新体验</div>
            <div className="video-shadow"></div>
            <img className="img1" src="https://search-operate.cdn.bcebos.com/455bffa2f1dca0838fe3015eb57bbb7a.png" alt="" width={320}></img>
            <video className="video1" src="https://search-operate.cdn.bcebos.com/56e95346d8a11006b2d5437a9cf118a7.mp4" preload="auto" loop playsInline autoPlay muted="muted"></video>
            <div className="box1" style={{display: "flex", gap: 20, flexWrap: "wrap", justifyContent: "center", color: "#ddd", padding: "0px 20px"}}>
                <div style={{backgroundColor: "rgba(118,90,253,0.5)", maxWidth: 350, flex: 1, minWidth: 250, height: 80, borderRadius: 20, display: "flex", alignItems: "center", gap: 20}}>
                    <MobileOutlined style={{fontSize: 50, marginLeft: 20}}/>
                    <div style={{flex: 1}}>
                        <div>方法一</div>
                        <div>微信搜索小程序"AIPal"体验</div>
                    </div>
                </div>
                <div style={{backgroundColor: "rgba(118,90,253,0.5)", maxWidth: 350, flex: 1, minWidth: 250, height: 80, borderRadius: 20, display: "flex", alignItems: "center", gap: 20}}>
                    <DesktopOutlined style={{fontSize: 50, marginLeft: 20}}/>
                    <div style={{flex: 1}}>
                        <div>方法二</div>
                        <div>电脑端立即体验</div>
                    </div>
                </div>
            </div>
            <div className="footer">
                <img src="https://search-operate.cdn.bcebos.com/db3b1d2d67427d23bfc6caac70d307c2.png" alt="" width={1440}/>
                <div style={{color: "#555", textAlign: "center", marginTop: -80}}>
                    <div>Design By <span style={{fontWeight: 800, textDecoration: "underline", cursor: "pointer"}} onClick={() => window.open("https://github.com/XiaoBaiOvO")}>Ethan Hong</span></div>
                    <div style={{fontSize: 15}}>Copyright © 2021 - 2023 Ebai Co.</div>
                </div>
            </div>
        </div>
    )
}