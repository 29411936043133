import axios from "axios";
import {setUserInfo} from "../store/userSlice";

export const doLogin = async body => request("user/login", body);
export const doCheck = async body => request("user/check", body);
export const doLogout = async body => request("user/logout", body);
export const requestGetNewCode = async body => request("gift/getNewCode", body);
export const requestGetCodeTotal = async body => request("gift/getCodeAmount", body);
export const requestGetCodeHistory = async body => request("gift/getCodeHistory", body);
export const getUserList = async body => request("training/admin/gifting", body);

export const initAxios = dispatch => {
    axios.defaults.validateStatus = status => {
        [400, 401, 500].includes(status) && dispatch(setUserInfo({}))
        return true
    }
}
let token = localStorage.getItem("X-Access-Token") ?? ""
export const setToken = (newToken) => {
    token = newToken
    localStorage.setItem("X-Access-Token", newToken)
}
const request = async (url, body) => {

    return (await axios.post("/api/" + url, body,
        {
            headers: {
                "X-Access-Token": token
            }
        })).data.object
}