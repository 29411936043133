const calcTime = date => {

    // 获取当前时间和给定日期之间的毫秒差
    const time = new Date() - new Date(date);

    // 判断时间差是否小于等于0
    if (time <= 0) {
        return '未来';
    }

    // 获取相差的秒数、分钟数、小时数、天数、周数、月数、年数
    const second = Math.floor(time / 1000);
    const minute = Math.floor(second / 60);
    const hour = Math.floor(minute / 60);
    const day = Math.floor(hour / 24);
    const week = Math.floor(day / 7);
    const month = Math.floor(day / 30);
    const year = Math.floor(day / 365);

    // 根据相差的时间单位返回对应的字符串
    if (year > 0) {
        return `${year}年前`;
    } else if (month > 0) {
        return `${month}个月前`;
    } else if (week > 0) {
        return `${week}周前`;
    } else if (day > 0) {
        return `${day}天前`;
    } else if (hour > 0) {
        return `${hour}小时前`;
    } else if (minute > 0) {
        return `${minute}分钟前`;
    } else {
        return '刚刚';
    }

}

const formatTime1 = time => {

    let month = formatNumber(time.getMonth()+1);
    let date = formatNumber(time.getDate());

    let hour = formatNumber(time.getHours());
    let minutes = formatNumber(time.getMinutes());


    return month + "/" + date + " " + hour + ":" + minutes;

}

const formatNumber = n => {
    n = n.toString()
    return n[1] ? n : `0${n}`
}

module.exports = {
    calcTime,
    formatTime1
}
