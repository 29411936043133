import React from 'react';

const Header = () => {

    return (
        <>
            <div style={{width: "100%", padding: "20px 0 60px", textAlign: "center", fontSize: 14, color: "#999", flexWrap: "wrap", justifyContent: "center", gap: "0 20px"}}>
                <div>Copyright © 2016 - 2023 LeanOn</div>
                {/*<div>Design By <span style={{color: "#999", fontWeight: 800, textDecoration: "underline", cursor: "pointer"}} onClick={() => window.open("https://github.com/XiaoBaiOvO")}>Ethan Hong</span></div>*/}
            </div>
        </>
    )
}

export default Header;