import React, {useEffect} from 'react';
import Header from "./header";
import {Outlet, useNavigate} from "react-router-dom";
import Footer from "./footer";
import {doCheck, setToken} from "../../config/api";
import {setUserInfo} from "../../store/userSlice";
import {useDispatch} from "react-redux";

const LayoutPage = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() =>{
        doCheck().then(r => {
            if (!r.result) {
                navigate("/login")
                setToken("")
            } else {
                dispatch(setUserInfo(r.userInfo))
            }
        })
    }, [])
    return (
        <div style={{backgroundColor: "#EEEEEE", minHeight: "100vh"}} className="scrollbar">
            <Header />
            <div>
                <Outlet />
                <Footer />
            </div>
            {/*<Footer />*/}
        </div>
    )
}

export default LayoutPage;