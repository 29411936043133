import React from 'react';
import {Dropdown} from "antd";
import {LogoutOutlined} from "@ant-design/icons";
import {doLogout, setToken} from "../../config/api";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

const Header = () => {

    const navigate = useNavigate();
    const {userInfo} = useSelector(state => state.user);

    const logout = () => {
        doLogout().then(r => {
            navigate("/login")
            setToken("")
        })
    }

    return (
        <>
            <div className="header">
                <div style={{flex: 1, fontSize: 22, marginLeft: 20, display: "flex", gap: 20}}>LeanOn
                    {/*<div style={{display: "flex", gap: 20, fontSize: 15, color: "#ccc"}}>*/}
                    {/*    <div style={{borderRadius: 15, border: "1px #ccc solid", padding: "3px 10px"}} onClick={() => navigate("/")}>派样机取货码</div>*/}
                    {/*    <div style={{borderRadius: 15, border: "1px #ccc solid", padding: "3px 10px"}} onClick={() => navigate("/gift")}>堂训签到名单</div>*/}
                    {/*</div>*/}
                </div>
                {/*<div style={{marginLeft: "auto"}}>{userInfo.nickName}</div>*/}
                <Dropdown menu={{
                    items: [
                        {
                            key: '1',
                            label: (
                                <div onClick={logout}>
                                    <LogoutOutlined/> 退出登录
                                </div>
                            ),
                        }
                    ]
                }}>
                    <div style={{margin: "0 20px 0 auto", alignItems: "center", display: "flex", gap: 5}}>
                        {userInfo.nickName ? <img src={"https://api.multiavatar.com/" + userInfo.nickName + ".png"} alt="" height={30}/> : null}
                        <div style={{fontSize: 18}}>{userInfo.nickName}</div>
                    </div>
                </Dropdown>
            </div>
        </>
    )
}

export default Header;