import React from "react";
import Result404 from "../pages/result/404"

import Layout from "../pages/layout"
import Main from "../pages/main"
import Login from "../pages/login"
import Gift from "../pages/gift";
import Gifting from "../pages/gifting";
import Test from "../pages/test";

// 路由表
export const routes = [
    {
        path: "/",
        element: <Layout/>,
        // element: <Main/>,
        children: [
            {
                index: true,
                element: <Main/>,
                // element: <Gift/>,
            }
        ]
    },
    {
        path: "/gift",
        element:  <Gift/>
    },
    {
        path: "/gifting",
        element: <Gifting/>
    },
    {
        path: "/aipal",
        element: <Test/>
    },
    {
        path: "/login",
        element: <Login/>,
    },
    {
        path: "*",
        element: <Result404/>,
    }
];