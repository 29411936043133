import React, {useEffect, useState} from "react";
import {Button, Dropdown, Empty} from "antd";
import {requestGetCodeHistory, requestGetCodeTotal, requestGetNewCode} from "../config/api";
import {DownOutlined} from "@ant-design/icons";
import {useRequest} from "ahooks";
import {formatTime1} from "../util/timeUtil";

const Main = () => {

    const {loading: getCodeLoading, runAsync: doGetNewCode} = useRequest(requestGetNewCode, {manual: true});
    const {loading: loading1, runAsync: run1} = useRequest(requestGetCodeTotal, {manual: true});
    const {loading: loading2, runAsync: run2} = useRequest(requestGetCodeHistory, {manual: true});

    const [history, setHistory] = useState([])
    const [local, setLocal] = useState("杭州金沙天街店")
    const [code, setCode] = useState("---- ----")
    const [total, setTotal] = useState([0, 0])
    const [newCount, setNewCount] = useState(0)

    useEffect(() => {
        run1().then(r => setTotal(r))
        run2().then(r =>
            setHistory(r?.map(item => {
                return {
                    code: (item.code.slice(0,4) + " " + item.code.slice(4,8)),
                    date: item.serviceTime
                }
            })))
    }, [])

    const getNewCode = () => {
        doGetNewCode({local}).then(r => {
            // const newCode = (Math.random().toString().slice(-4)) + " " + (Math.random().toString().slice(-4))
            if (r) {
                const newCode = (r.slice(0,4) + " " + r.slice(4,8))
                setCode(newCode)
                let newHistory = history
                newHistory.unshift({code: newCode, date: formatTime1(new Date())})
                setHistory(newHistory)
                setNewCount(newCount + 1)
            } else {
                setCode("取货码库存不足")
            }
        })
    }

    return (
        <div style={{padding: 20}}>

            <div style={{padding: 20, borderRadius: 20, maxWidth: 600, margin: "0 auto", backgroundColor: "white"}}>
                <div style={{display: "flex"}}>
                    <div style={{fontSize: 18, fontWeight: 800, whiteSpace: "nowrap"}}>派样机取货码</div>
                    {/*<Dropdown*/}
                    {/*    menu={{*/}
                    {/*        items: [*/}
                    {/*            {*/}
                    {/*                key: '杭州金沙天街店',*/}
                    {/*                label: (*/}
                    {/*                    <>杭州金沙天街店</>*/}
                    {/*                ),*/}
                    {/*            },*/}
                    {/*            {*/}
                    {/*                key: '杭州In77店',*/}
                    {/*                label: (*/}
                    {/*                    <>杭州In77店</>*/}
                    {/*                ),*/}
                    {/*            },*/}
                    {/*            {*/}
                    {/*                key: '临平店',*/}
                    {/*                label: (*/}
                    {/*                    <>临平店</>*/}
                    {/*                ),*/}
                    {/*            },*/}
                    {/*            {*/}
                    {/*                key: '城西银泰店',*/}
                    {/*                label: (*/}
                    {/*                    <>城西银泰店</>*/}
                    {/*                ),*/}
                    {/*            }*/}
                    {/*        ],*/}
                    {/*        selectable: true,*/}
                    {/*        defaultSelectedKeys: ['杭州金沙天街店'],*/}
                    {/*        onClick: (e) => setLocal(e.key)*/}
                    {/*    }}>*/}
                    {/*    <div style={{marginLeft: "auto", alignItems: "center", display: "flex", justifyContent: "right", gap: 5}}>*/}
                    {/*        <div style={{textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}}>*/}
                    {/*            {local}*/}
                    {/*        </div>*/}
                    {/*        <DownOutlined/>*/}
                    {/*    </div>*/}
                    {/*</Dropdown>*/}
                </div>
                <div style={{textAlign: "center", fontSize: code === "取货码库存不足" ? 20 : 50, padding: "30px 0", fontFamily: "digitalFont"}}>
                    {getCodeLoading ?
                        <div style={{margin:"16.5px auto", display: "flex", gap: 20, justifyContent: "center"}}>
                            <div className="loading">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            <div className="loading">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div> : code}
                </div>
                <Button block type="primary" loading={getCodeLoading || loading1 || loading2} style={{color: "rgb(205,218,185)"}} onClick={getNewCode} >{newCount === 0 ? "获取取货码" : "重新获取"}</Button>
                <div style={{textAlign: "right", paddingTop: 20, fontSize: 14}}>剩余数: {total?.[0] - newCount} / {total?.[1]}</div>
            </div>



            <div style={{
                padding: 20,
                borderRadius: 20,
                maxWidth: 600,
                margin: "20px auto 0",
                backgroundColor: "white"
            }}>
                <div style={{display: "flex"}}>
                    <div style={{marginBottom: 10, fontSize: 18, fontWeight: 800, whiteSpace: "nowrap"}}>取货码获取记录</div>
                    <div style={{marginLeft: "auto", lineHeight: "26px", whiteSpace: "nowrap"}}>总计: {history?.length} 个</div>
                </div>
                {history?.length > 0 ?
                    history?.map((item, index) =>
                        <div key={index} style={{margin: "10px 0", borderBottom: "1px solid gray"}}>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <div style={{fontSize: 18, fontWeight: 800}}>{item.code}</div>
                                <div style={{marginLeft: "auto", fontSize: 14}}>{item.date}</div>
                            </div>
                        </div>
                    ) :
                    <Empty style={{padding: "40px 0"}}/>
                }
            </div>

        </div>
    );
};

export default Main;